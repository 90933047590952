/* You can add global styles to this file, and also import other style files */
// Starting point theme colors, which can be overridden using ThemeService.
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../../_library/shared/_styles/modal.scss';
@import '../../_library/shared/_styles/buttons.scss';
@import '../../_library/shared/_styles/headers.scss';

em {
  @apply bg-yellow-100 italic p-1
}

.sidebar-content-half {
  width: 64px;
  @apply fixed
}

.sidebar-content {
  width: 176px;
  @apply fixed h-full
}
.main-content-wrapper {
  height: calc(100vh - 64px);
  @apply fixed top-[64px] w-full;
}

.main-content-half {
  width: calc(50vw - 62px);
  @apply h-full overflow-y-scroll
}

.main-content-full {
  @apply grow h-full overflow-y-scroll w-full
}

.main-content {
  @apply w-screen grow h-full overflow-y-scroll
}

@layer base {
  .progress-bar {
    @apply bg-accent-500
  }

  .input-default {
    @apply shadow-sm focus:ring-dark-500 focus:border-dark-500 block w-full sm:text-sm border-gray-300 rounded-md
  }

  .input-error {
    @apply shadow-sm focus:ring-dark-500 border-red-500 block w-full sm:text-sm border-gray-300 rounded-md
  }

  .badge {
    @apply ml-1 mr-1 inline-flex items-center px-2.5 pt-0.5 rounded-full text-xs font-medium leading-tight
  }

  .thread-list {
    @apply min-w-300 max-w-300 md:max-w-400 flex-none;
  }

  .menuItemActive {
    @apply relative w-full truncate bg-accent-600 hover:bg-accent-100 text-white flex justify-between px-2 py-2 text-base font-medium rounded-md cursor-pointer
  }

  .menuItemDefault {
    @apply relative w-full truncate bg-accent-800 hover:bg-accent-600 text-white flex justify-between px-2 py-2 text-base font-medium rounded-md cursor-pointer
  }

  .subMenuItemActive {
    @apply flex font-medium bg-accent-600 hover:bg-accent-300 p-1 items-center relative rounded-md text-base text-white truncate w-full
  }

  .subMenuItemDefault {
    @apply flex font-medium hover:bg-accent-400 p-1 items-center relative rounded-md text-base text-white truncate w-full
  }

  .item-list {
    @apply p-0 md:p-1 w-full h-full;

    ul {
      @apply bg-white shadow divide-y divide-gray-200;
      li {
        @apply w-full cursor-pointer hover:bg-gray-100 border-b border-gray-50;
        a {
          @apply px-3 py-1 flex flex-col content-start;
        }
      }
    }

    .selected {
      @apply bg-blue-200 hover:bg-blue-100;
      .activity {
        @apply bg-blue-100;
      }
    }

    .unread {
      @apply border-l-2 border-red-500;
    }

    .left {
      @apply flex-none align-top pl-2 pt-2;
    }

    .right {
      @apply flex-none pr-2 m-auto;
    }

    .middle {
      @apply p-2 grow pl-3;
      .dark {
        @apply text-sm font-medium text-dark-600;
      }
      .gray {
        @apply mt-0.5 text-sm text-gray-500 align-top;
      }
    }

  }

  .time {
    @apply text-xs italic truncate text-gray-500;
  }

  .chicklet {
    @apply cursor-pointer rounded-md items-center py-0.5 px-2 hover:invert mr-1.5 text-sm font-medium bg-dark-100 text-dark-700;
  }

  .stick {
    @apply absolute top-4 left-1/2 z-20;
  }

  .progressive-p {
    @apply p-0 md:p-2 lg:p-4;
  }
}

.video {
  @apply m-10 w-400 h-225 md:w-560 md:h-315 lg:w-700 h-394;
}

.firebase-emulator-warning {
  display: none;
}
