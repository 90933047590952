@import '../../nxto/src/styles';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap');

.Garamond {
  font-family: 'EB Garamond', serif !important;
}

@layer base {
  .item-box {
    @apply mt-1 p-3 bg-white hover:bg-gray-100 cursor-pointer;
  }
}

